body {
//   font-family: "Open Sans", sans-serif;
//   font-family: 'Roboto', sans-serif;
   font-family: -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
   font-weight: 400;
   line-height: 1.5;
}

.topic_nav,
.navbar,
.breadcrumb {
//   font-family: 'Roboto', sans-serif;
}
      
h1,h2,h3,h4,h5 {
   color: $robert_main_medium;
   text-transform: uppercase;
//   color: $black;
//   font-style: italic;
//   padding-top: $large-pad;
}

.content {
   & > h1,
   & > h2,
   & > h3 {
      
      &:not(:first-child) {
         margin-top: $large-pad;
      }
   }
}


.head_description {
   font-style: italic;
   color: $medium-grey;
}

h1,
h2 {
   @include media-breakpoint-down(sm) {
      font-size: 1.5em;
   }
}


.jumbotron {
//   font-family: 'Roboto', sans-serif;
   color: $white;
   h1,h2,h3,h4,h5 {
//      font-family: 'Roboto', sans-serif;
   }
   a,
   a:link,
   btn {
      color: $white !important;
   }

   .btn-primary {
      color: $white;
      background-color: $robert_main_medium;
      border-color: $robert_main_mediumlight;
   }
}

// Social Media - Font Awesone Styles

.fa {
   padding: 20px;
   font-size: 30px;
   width: 50px;
   text-align: center;
   text-decoration: none;
   margin: 5px 2px;

   :hover {
      opacity: 0.7;
   }

   fa-facebook {
      background: #3B5998;
      color: white;
   }

   .fa-twitter {
      background: #55ACEE;
      color: white;
   }

   .fa-google {
      background: #dd4b39;
      color: white;
   }
}

.rdt {
   color: $rdt_color;
}


.bgf {
   color: $bgf_color;
}

.jumbotron {
   color: white;

   * { color: white }
   h1,h2,h3,h4,h5,h6 {
      color: $robert_main_light;
   }
}
