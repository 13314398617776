// footer

footer {
   .footer_teaser,
   .subfolder_nav,
   .footer_con,
   .footer_nav {

	 padding-top: $large_pad;
         padding-bottom: $large_pad;
         background-color: $robert_main;
         color: white !important;
//         @extend .shadow_text;

         font-weight: 450;
         strong,
         b {
            font-weight: 600;
         }
         a,
         a:link {
           color: white;          
         }

         .address_contacts,
         .openinghours,
         .foot_freetext {
            a,
            a:link {
               @include alpha-background-color(rgba(black, 0.1), $base_color);
               @include rounded( rem(4));
               padding: rem(4);
               color: white;
            }
         }


         &.first {
            padding-top: $large-pad;
         }
         &.last {
            padding-bottom: 0;
            margin-bottom: 0;
         }

         h3,
         h4 {
//            border-bottom: 1px solid $light_grey;
            color: $white;
            font-size: rem(22);
            font-weight: 650;
            line-height: 27px;
            text-transform: uppercase;
            padding-top: 0;
            margin-top: 0;
            margin-bottom: $medium-pad; 
            //padding: 0 0 10px;
         }
         h4 { font-size: rem(18); }

         .pgp_fingerprint {
            font-weight: 400;
            font-size: rem(12); 
         }
   }
   .footer_con {
//      background-color: $robert_main_medium;
        background-color: transparent !important;
   }

   .wappen {
      padding-top: $medium_pad;
   }

   .footer_nav {
      padding-top: $small_pad;
      background-color: $grey;
   }

   .subfolder_nav {
      background-color: $robert_main;
   }

   .footer_teaser {
      background-color: $robert_main_mediumlight;
   }


   

//      .company {
//         padding-top: $medium-pad;
//         padding-bottom: $medium-pad;
//
//         & > span {
//            word-wrap: normal;
//         }
//         @extend .shadow_text;

//   margin-top: rem(50);
//   @include media-breakpoint-down(md) {
//      margin-top: $medium-pad;
//   }
//   @include media-breakpoint-down(sm) {
//      margin-top: $small-pad;
//   }

//      color: $medium-grey;
//      background-color: $robert_main;
//      @extend .shadow_mini;
//      @extend .shadow_text;

   .copyright {
      margin-top: $small-pad;
      font-size: rem(12);
      text-align: center;
      color: lighten($robert_main_light, 10%);
      a,
      a:link {
         color: $white;
//         color: lighten($medium-grey, 15%);
      }
   }
}
