.teaser_row {
   padding: $medium-pad;
   min-height: rem(100);
}


div.ZMSTeaserContainer > div {
   display: inline-block;
   margin-right: $medium_pad;
   float: left;
   width: 250px !important;
   min-height: 180px;
   @include media-breakpoint-down(sm) {
      justify-content: center !important;
      align-items: center !important;
   }
   img { max-width: 250px !important; }
}

div.ZMSTeaserElement {
   width: 200px;
}




