// site

html,
body {
   background-color: $robert_main_medium;
}

* {
   box-sizing: border-box;
}


::selection {
   background-color: $light-grey;
}

::-moz-selection {
   background-color: $light-grey;
}

main {
  background-color: $white !important;
  padding-top: $large_pad;
  padding-bottom: $large_pad;
  h1.title {
     overflow-wrap: break-word;
  }
  img {
     max-width: 100%;
     height: auto;
  }

}

