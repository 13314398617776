// ZMS default modifyers

.ZMSLinkElement,
.ZMSFile {
   margin-bottom: $medium-pad;
}

.ZMSLinkElement {

   &.external a:before {
      color: $grey;
      margin-right: rem(4);
      @include font-mdi("\f0137");
   }
   &.internal a:before {
      color: $grey;
      margin-right: rem(4);
      @include font-mdi("\f0339");
   }
}


.ZMSTable {
   caption {
      font-size: rem(14);
   }
}
