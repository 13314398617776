// grid stuff */



.content {
   margin-top: $large-pad;
   padding-bottom: $large_pad;
//   flex-wrap: wrap;

   & > *:first-child {
      margin-top: 0 !important;
   }

   .alert {
      margin-bottom: $large-pad;
   }

}



