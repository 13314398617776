// nav stuff

.btn-link:hover { color: $light_grey; }

.navbar {
}


.topic_nav {

   .navbar-nav {
      flex-flow: row wrap !important;
   }
}


.top_nav {

   font-size: rem(18);

   ul {
      width: 100% !important;
   }

   .dropdown-menu {
      @extend .shadow_light;
   }


.dropdown-toggle::after {
   content: "";
   border: none;
   margin-left: 0;
}

   .navbar-toggler {
      color: $white !important;
      background-color: $robert_main_dark;
   }

   .dropdown-item {
      a,
      a:link,
      a:visited {
         color: black;
      }
   }
   ul ul {
      width: auto !important;
   }

   .navbar-nav a.nav-link {
      color: $white !important;
//      @include opacity(0.9);
//      @extend .shadow_text;
   }

   .mdi {
      @include media-breakpoint-down(sm) {
         font-size: rem(18);
      }
   }
   .navbar-brand {
      display: none;
      @include media-breakpoint-down(sm) {
         display: inline;
      }
   }
   .search_app_mobile {
      @extend .rounded;
      width: 100%;
      padding: $medium-pad;
      background-color: $white;
      margin-top: $medium-pad;
      margin-left: -$medium-pad;
      margin-right: -medium-pad;
      margin-bottom: 0;
   }
   .btn-link {
      color: $white;
   }
      #home-pick {
         a {
            color: $white !important;
            &:link {
               color: $white !important;
            }
         }
      }
}


ol.breadcrumb {
//   @extend .shadow_mini;
   color: $black !important;
   background-color: transparent;
   font-size: rem(15);
   padding: 0.5rem;
   margin-bottom: 0 !important;

   li,
   a,
   a:link {
      color: $black;
   }
}



// footer

.footer_nav,
.subfolder_nav,
.social_nav,
.teaser_row {
      font-size: rem(14);

   h3 {
      border-bottom: 1px solid $medium_grey;
      color: $white;
      font-size: rem(24);
      font-weight: 600;
      line-height: 27px;
      text-transform: uppercase;
      padding-top: 0;
      margin-top: 0;
//      padding: 20px 0 10px;
   }

   ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      margin-top: 15px;
      color: $white;

      li,
      {
         a {
            padding: 0 0 5px 0;
            display: block;
            color: $white;
            &:link {
               color: $white;
            }
         }
      }

   }
}



.footer_nav {
   
   & > .row {
      @include media-breakpoint-down(sm) {
         display: block;
      }
   }
}


