// header


header {

   .head-btns-right {
      width: auto;
        padding: $medium-pad;
        padding-right: 0;
//        font-size: rem(20);

        i.mdi {
//           font-size: 1.5em;
        }

        @include media-breakpoint-down(lg) {
            font-size: rem(18);
        }
        @include media-breakpoint-down(md) {
           margin: 0 auto !important;
           float: none !important;
        }
   }



   .container-fluid.top_bar {
      @include media-breakpoint-down(sm) {
         padding-right: 0;
         padding-left: 0;   
      }
     & > .row {
         @include media-breakpoint-down(sm) {
            margin-right: -15px;
            margin-left: -15px;
         }
      }
   }


   .top_bar {
//      @extend .shadow_mini;
      .container {
         @include media-breakpoint-down(sm) {
            padding-right: 0;
            padding-left: 0;
         }
      }

      div.eye_row { }


      background-color: $top-panel-bg;
	
       .menu_panel {
//          background-color: $white !important;
//          background-color: $robert_main_light !important;
          z-index: 200;
            color: $white;

          .nav-link {
             @include media-breakpoint-down(md) {
//                padding: .3em .2em;
             }
          }
       }

       .fg {
          z-index: 10;
       }

       .fg2 {
          z-index: 2;
       }

       .dropdown-menu { z-index: 500; }


   }

   .bread_line_row {
      background-color: $light_grey;
   }


div#carousel1 {
    overflow: hidden;

//    min-height: 400px;



#carouselButtons1 {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $white;
    z-index: 200;

    .fa {
       width: rem(20);
       line-height: 0.75;
       font-size: rem(14);
       padding: rem(2);
    }
}

   .carousel-indicators li {
       text-indent: initial !important;
       background-color: transparent !important;
       width: auto;
       margin-left: rem(4);
       margin-right: rem(4);
    }

   .ci-wappen {
      background-color: rgba($robert_main_dark, 0.5);
      bottom: 0;
      margin: 0;
      height: 110px;
      z-index: 99;

//display: -webkit-box;
//display: -ms-flexbox;
//display: flex;
//-webkit-box-align: center;
//-ms-flex-align: center;
//align-items: center;
//-webkit-box-pack: center;
//-ms-flex-pack: center;
//justify-content: center;

      li {
        img {
           width: 80%;
           vertical-align: middle;
           -webkit-transition: width .25s ease;
           transition: width .25s ease;
           height: auto;

         }
        &.active img {
          width: 100%;
        }
      }
    }


    @include media-breakpoint-down(lg) {
//       min-height: 317px;
    }
    @include media-breakpoint-down(md) {
//       min-height: 235px;
    }
    @include media-breakpoint-down(sm) {
//       min-height: 107px;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
}



}
