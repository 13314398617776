#search_results {
   .header {
      margin-top: $medium_pad;
      margin-bottom: $medium_pad;
   }
   h2 {
      font-size: rem(20);
   }
   .breadcrumb {
      width: 100%;
      background-color: $light-grey;
   }
   p {
      margin-top: $medium_pad;
      margin-bottom: $large_pad;
   }

   .pagination {
      font-size: rem(20);
      line-height: 1.5;
      .fa {
         padding: 0;
         font-size: rem(20);
         line-height: 1.25;
         width: auto;
      }
      a,
      a:visited {
         line-height: 1.5;
         text-decoration: none;
         border-bottom: 1px #dee2e6 solid;
      }
      li {
         padding-left: 0;
         margin-left: 0;

         &:before {
         content: "";
         }

       }
    }

}
