// ZMS custom modifyers


   .card-header {
      color: $white;
      background-color: $robert_main !important;
      a,
      button,
      a:link {
         color: $white;
      }
   }


.horde_cal,
.sy_news_ov,
 {

   margin-bottom: $large_pad;

   .card { border: 0; }

   .card-header,
   .card-footer {
      color: $white;
      background-color: $robert_main !important;
      a,
      button,
      a:link {
         color: $white;
      }
   }

   button {
      text-align: left !important;
   }

  .loc,
  .url,
  .summary {
     margin-top: $medium_pad;
  }

  .bd_head,
  .card-body {
     padding: $medium_pad;
     padding-left: $large_pad;
  }

  .bd_head {
     width: 100%;
     padding-right: $large_pad;
     background-color: $light-grey;

     .dur {
        font-style: italic;
        color: $medium-grey;
     }
  }
}

.TOC_Index {
   margin-top: $large-pad;
}


// srg_cal_timestamp
span.srg_timestamp {
   font-size: 0.75rem;
}


// card w image

.card {
    max-width: 100%;
}

.card-text {
   overflow-wrap: break-word;
   word-wrap: break-word;
}


.card.infolink {
   @include media-breakpoint-down(sm) {
      margin-bottom: $large-pad;
      .topimg {
         img {
            width: 100% !important;
         }
      }
      h4 {
         margin-top: $medium-pad;
      }
   }
}


.pf-carousel  {
    .pf-item-description {
       a {
          color: $light-grey !important;
       }
    }
}



// formats
.alert {
   @each $color, $value in $theme-colors {
      &.alert-#{$color} {
         a {
            color: darken($value, 10%);
            font-weight: $alert-link-font-weight;
         }
      }
   }
}

// products
div.prod_timespan {
   margin-bottom: $large-pad;

   .date,
   .custom {
      font-weight: bold;
   }
}

div.prod_legend {
   margin-top: $large-pad;
}

// angebote / products


div.text_sub {
   margin-top: $large-pad;
   margin-bottom: $large-pad;
//   font-weight: bold;
}


.prod_free_content {
   margin-top: $large-pad;
   table {
      width: 100% !important;

      td,
      th {
         text-align: left !important;
         width: 30% !important;

         & + td,
         & + th {
            width: auto;
            text-align: right !important;
         }
      }

      // break on smallest screens
      @include media-breakpoint-down(xs) {
         tr {
            display: table;  width:100%;
         }

         th {
            background-color: $robert_main_light;
         }

         td,
         th {
            display: table-row;
            text-align: center !important;
//          padding-right: $small-pad !important;

            & + td,
            & + th {
               text-align: center !important;
            }

            img {
               max-width: 100%;;
               margin: 0 auto;
            }
         }
         th {
            display: table-row;
         }
         tr {
            margin-bottom: $large-pad;
         }
      }
   }
}


.prod_teaser_action {
   margin-top: $medium-pad;
   margin-left: $large-pad;
//   width: 30%;
   @include media-breakpoint-down(xs) {
//      width: 80%;
   }
   z-index: 600;
   margin-right: $medium-pad;
   padding: $small-pad;
   background-color: $robert_main_light;
   @include rounded();
   @include rotate(10deg);
   @extend .shadow_light;
   h5 {
      cont-size: rem(20);
      color: $red;
      font-weight: bold;
      font-style: none;
   }
   .teaser_text_sub {

   }
}


div.ad_banner {
  margin-bottom: $large-pad;
  margin-top: $large-pad;
  img {
     width: 100% !important;
  }
}


.ZMSGraphic {

    .graphic {
 //      width: auto !important;
       max-width: 100% !important;
    }

   .center {
//      text-align: center;
   }
}

.syshow {
   margin-bottom: $large-pad;
   &.halfwidth {
      max-width: 50%;
      @include media-breakpoint-down(md) {
         max-width: 100%;
      }
   }

   .carousel {
      overflow: hidden;
     .carousel-control-prev:focus,
     .carousel-control-next:focus {
        border-color: red;
        border-width: 3px;
        border-style: dotted;
        background-color: red;
      }
     .carousel-caption {
        bottom: 0;
        left: 0;
        width: 100%;
        background: transparent; /* Fallback IE 6-8 */
        background-color: rgba(0, 0, 0, .30);
        h5.item-title {
          font-weight: bold;
          color: $robert_main_light;
          @extend .shadow_text;
        }
        p.item-description {
           @extend .shadow_text;
           padding-bottom: $medium-pad;
           a {
             color: $light-grey !important;
           }
        }
       .carousel-item > img {
       }
     }
   }
}

.osm_map {
   margin-bottom: $large_pad;
}
