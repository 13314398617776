
/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

/* @import "compass/reset"; */


@import "base/settings";
//@import "base/bootstrap/variables";
@import "utils/page_setup";

// $icon-font-path: "../fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
//@import "base/bootstrap/_backports.scss";

// fancybox
@import "../node_modules/fancybox/dist/scss/jquery.fancybox.scss";

@import "base/fonts";

@import "base/mixins";
@import "base/typography";
@import "utils/functions";

@import "../node_modules/jquery-ui/themes/base/all.css";

// scrollto (gallery?)
@import "../node_modules/jquery-scrollto/out/styles/style.css";


// portfolio
@import "../node_modules/portfolio-js/dist/portfolio.css";
@import "../node_modules/lightbox2/dist/css/lightbox.min.css";


// leaflet (OSM)
@import "../node_modules/leaflet/dist/leaflet.css";

@import url('https://www.lindenberg-eichsfeld.de/f_css_defaults') screen, print;

// neu (temp aus)
//@import "layout/nplayer";

// images
@import "layout/images";

// new
@import "layout/header";
@import "layout/footer";
@import "layout/grid";
@import "layout/nav";
@import "layout/zmselements";
@import "layout/zmscustom";
@import "layout/figures";
//@import "layout/panels";
@import "layout/lists";
@import "layout/forms";
@import "layout/picker";
@import "layout/teaser";
@import "layout/objects";
@import "layout/search_page";

// bisher noch aus -> default player theme
//@import "layout/jwplayer";

@import "layout/site";
@import "layout/rimi";
