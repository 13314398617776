.rimi-aemter-list,
.rimi-staff-list,
.rimi-dienstleistungen-list,
.rimi-sachgebiete-list {
   margin-bottom: $double_pad;
}

.rimi-aemter-list {
   margin-top: $large_pad;
}
